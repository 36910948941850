<template>
  <div class="registration-flow-container">
    <div class="flow">
      <FlowItem :active="!status.companyInfoRegistered || !status.usersRegistered"
        :done="status.companyInfoRegistered && status.usersRegistered">
        <template v-slot:title>
          {{$t('WIZARD.SALES_MANAGER_INFO')}}
        </template>
        <template v-slot:icon>
          <font-awesome-icon class="va-m" icon="landmark"></font-awesome-icon>
        </template>
      </FlowItem>

      <div class="divider"></div>

      <FlowItem :active="
        !status.companyAddonRegistered||
        !status.categoryRegistered||
        !status.deliverableRegistered||
        !status.verificationRegistered" 
        :done="status.verificationRegistered">
        <template v-slot:title>
          <br />{{$t('WIZARD.DETAILED_INFO')}}
        </template>
        <template v-slot:icon>
          <font-awesome-icon class="va-m" icon="building"></font-awesome-icon>
        </template>
      </FlowItem>

      <template v-if="
        !status.companyAddonRegistered||
        !status.categoryRegistered||
        !status.deliverableRegistered||
        !status.verificationRegistered">
        <div v-if="status.openSupplier" class="divider"></div>
        <div v-if="status.openSupplier">
          <FlowItem :active="status.companyAddonRegistered" :done="status.buyerRegistered">
            <template v-slot:description>
              <span :title="$t('WIZARD.STEP_SELECT_BUYER')">{{$t('WIZARD.STEP_SELECT_BUYER')}}</span>
            </template>
          </FlowItem>
        </div>
        <div class="divider"></div>
        <FlowItem ref="flowItem_category" :active="status.buyerRegistered || (!status.openSupplier && status.accountCreated)"
          :done="status.categoryRegistered">
          <template v-slot:description>
            {{$t('WIZARD.STEP_SELECT_CATEGORY')}}
          </template>
        </FlowItem>
        <div class="divider"></div>
        <FlowItem ref="flowItem_area" :active="status.categoryRegistered" :done="status.deliverableRegistered">
          <template v-slot:description>
            {{$t('WIZARD.STEP_SELECT_DELIVERY_LOCATION')}}
          </template>
        </FlowItem>
        <div class="divider"></div>
        <FlowItem ref="flowItem_company" :active="status.deliverableRegistered" :done="status.companyAddonRegistered">
          <template v-slot:description>
            {{$t('WIZARD.STEP_COMPANY_DETAIL')}}
          </template>
        </FlowItem>
        <div class="divider"></div>
        <FlowItem ref="flowItem_verification" :active="status.companyAddonRegistered" :done="status.verificationRegistered">
          <template v-slot:description>
            <span :title="$t('WIZARD.STEP_VERIFICATION_INFO')">{{$t('WIZARD.STEP_VERIFICATION_INFO')}}</span>
          </template>
        </FlowItem>

        <div v-if="status.openSupplier" class="divider"></div>
        <div v-if="status.openSupplier">
          <FlowItem :active="status.verificationRegistered" :done="status.bankAccountRegistered">
            <template v-slot:description>
              {{$t('WIZARD.STEP_PAYMENT_ACCOUNT')}}
            </template>
          </FlowItem>
        </div>
      </template>

      <div class="divider"></div>
      
      <FlowItem :active="
        status.verificationRegistered &&
        (!status.productNameRegistered || !status.prRegistered)" 
        :done="status.registrationCompleted"
        :forceIcon="true">
        <template v-slot:icon>
          <font-awesome-icon class="va-m" icon="bullhorn"/>
        </template>
        <template v-slot:title><br/>{{$t('WIZARD.PR_INFO')}}</template>
      </FlowItem>
      
      <div class="divider"></div>
      <template v-if="!status.registrationCompleted && status.verificationRegistered && (!status.productNameRegistered || !status.prRegistered)" >
        <FlowItem ref="flowItem_pr" :active="status.verificationRegistered" :done="status.prRegistered">
          <template v-slot:description>
            {{$t('WIZARD.STEP_PR_INFO')}}
          </template>
        </FlowItem>
        <div class="divider"></div>
        <FlowItem 
          ref="flowItem_productName"
          :active="status.prRegistered" 
          :done="status.productNameRegistered">
          <template v-slot:description>
            {{$t('WIZARD.STEP_PRODUCT_NAME')}}
            (任意)
          </template>
        </FlowItem>
        <div class="divider"></div>
      </template>
      
      <div v-if="status.openSupplier">
        <FlowItem :active="status.bankAccountRegistered" :done="status.addressRegistered">
          <template v-slot:icon>
            <font-awesome-icon class="va-m" icon="clipboard-list"></font-awesome-icon>
          </template>
          <template v-slot:title>
            {{$t('WIZARD.STEP_BANK_ACCOUNT_INFO')}}
          </template>
        </FlowItem>
      </div>

      <div v-if="status.openSupplier" class="divider"></div>

      <FlowItem ref="flowItem_exam" :active="status.registrationCompleted" :done="status.buyerExaminationCompleted" no-input=true>
        <template v-slot:icon>
          <font-awesome-icon class="va-m" icon="eye"></font-awesome-icon>
        </template>
        <template v-slot:title>
          <template v-if="status.openSupplier">
            {{$t('WIZARD.OPEN_WAITING_CHECK')}}
          </template> 
          <template v-else>
            <br />{{$t('WIZARD.WATING_CHECK')}}
          </template>
        </template>
      </FlowItem>

      <div class="divider dashed double" v-if="status.openSupplier"></div>
      <div class="divider dashed double-next" v-if="status.openSupplier"></div>

      <div class='multi-step' v-if="status.openSupplier">
        <FlowItem :active="status.buyerExaminationCompleted" :done="status.addressVerified">
          <template v-slot:icon>
            <font-awesome-icon class="va-m" icon="clipboard-list"></font-awesome-icon>
          </template>
          <template v-slot:title>
            {{$t('WIZARD.STEP_ADDRESS_VERIFIED')}}
          </template>
        </FlowItem>
        <FlowItem :active="status.buyerExaminationCompleted" :done="status.contractSigned">
          <template v-slot:icon>
            <font-awesome-icon class="va-m" icon="fingerprint"></font-awesome-icon>
          </template>
          <template v-slot:title>
            <br />
            {{$t('WIZARD.STEP_CONTRACT_SIGN')}}
          </template>
        </FlowItem>
      </div>

      <div class="divider double-next" v-if="status.openSupplier"></div>
      <div class="divider double-reverse" v-if="status.openSupplier"></div>
      <div class="divider dashed" v-if="!status.openSupplier"></div>

      <FlowItem ref="flowItem_contract" :active="status.buyerExaminationCompleted" :done="status.contractSigned" v-if="!status.openSupplier">
        <template v-slot:icon>
          <font-awesome-icon class="va-m" icon="fingerprint"></font-awesome-icon>
        </template>
        <template v-slot:title>
          <br />
          {{$t('WIZARD.STEP_CONTRACT_SIGN')}}
        </template>
      </FlowItem>

      <div class="divider dashed" v-if="!status.openSupplier"></div>

      <FlowItem :active="status.contractSigned && status.addressVerified" :done="status.completed" no-input=true>
        <template v-slot:icon>
          <font-awesome-icon class="va-m" icon="check"></font-awesome-icon>
        </template>
        <template v-slot:title>
          <br />
          {{$t('WIZARD.REGISTER_COMPLETE')}}
        </template>
      </FlowItem>

    </div>
    <div :style="{'margin-top': status.registrationCompleted ? '0px': '100px'}" v-if="explain.content">
      <explain-block :at="explain.at">
        <div class="mx-3 my-2" v-html="explain.content"></div>
      </explain-block>
    </div>
  </div>
</template>

<script>
  import FlowItem from './FlowItem.vue'
  import ExplainBlock from '@/components/common/ExplainBlock.vue';
  export default {
    props: ['status', 'status.openSupplier'],
    components: {
      FlowItem,
      ExplainBlock
    },
    data() {
      return {
        explain: {
          at: '30.4vw',
          content: ``
        }
      }
    },
    mounted: function () {
      this.loadExplain(this.status)
    },
    watch: {
      status: {
        deep: true,
        handler(status) {
          this.loadExplain(status);
        }
      }
    },
    methods: {
      loadExplain(status) {
        var refId = 'flowItem_';
        if (status.buyerExaminationCompleted) {
          refId += 'contract';
          this.explain.content = this.$t('WIZARD.EXPLAIN_CONTRACT_SIGN');
        }else if (status.registrationCompleted && !status.buyerExaminationCompleted) {
          refId += 'exam';
          this.explain.content = this.$t('WIZARD.EXPLAIN_EXAMINATION');
        }else if (status.prRegistered) {
          refId += 'productName';
          this.explain.content = this.$t('WIZARD.EXPLAIN_PRODUCT_NAME');
        }else if (status.verificationRegistered) {
          refId += 'pr';
          this.explain.content = this.$t('WIZARD.EXPLAIN_PR_INFO');
        }else if (status.companyAddonRegistered) {
          refId += 'verification';
          this.explain.content =  this.$t('WIZARD.EXPLAIN_VERIFICATION_INFO');
        }else if (status.deliverableRegistered) {
          refId += 'company';
          this.explain.content = this.$t('WIZARD.EXPLAIN_COMPANY_INFO');
        } else if (status.categoryRegistered) {
          refId += 'area';
          this.explain.content = this.$t('WIZARD.EXPLAIN_DELIVERY_LOCATION');
        } else if (status.accountCreated) {
          refId += 'category';
          this.explain.content = this.$t('WIZARD.EXPLAIN_CATEGORY_INFO');
        }
        //TODO very hacky solution.
        var self = this;
        setTimeout(function(){
          if(self.$refs[refId]){
            let left = self.$refs[refId].$el.offsetLeft;
            if(refId === 'flowItem_exam' || refId === 'flowItem_contract'){
              self.explain.at = (left - 20) + 'px';
            }else self.explain.at = (left - 30) + 'px';
          }
        }, 100);
      }
    }
  }
</script>

<style scoped lang="scss">
  .registration-flow-container {
    border: 1px solid #EDEDED;
    padding: 1rem 1.5rem 1rem 1.5rem;
    min-height: 220px;

    .flow {
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      margin-top: 40px;
      margin-bottom: 40px;
      padding-left: 25px;
      padding-right: 25px;

      .divider {
        border-bottom: 4px solid #CCCCCC;
        flex-grow: 1;

        &.dashed {
          border-bottom: 4px dashed #CCCCCC !important;

          &.double-next {
            border-top: 4px dashed #CCCCCC !important;
          }

          &:after {
            border-right: 4px dashed #CCCCCC !important;
          }

          &:before {
            border-left: 4px dashed #CCCCCC !important;
          }
        }

        &.double {
          height: 50px;
          margin-top: -45px;
          flex: 0.5;

          &:after {
            height: 100px;
            border-right: 4px solid #CCCCCC;
            display: block;
            content: '';
            margin-right: -4px;
          }
        }
        
        &.double-reverse {
          height: 50px;
          margin-top: -45px;
          flex: 0.5;

          &:before {
            height: 100px;
            border-left: 4px solid #CCCCCC;
            display: block;
            content: '';
            margin-left: -4px;
          }
        }

        &.double-next {
          height: 104px;
          flex: 0.5;
          border-top: 4px solid #CCCCCC;
          border-bottom: 4px solid #CCCCCC;
          margin-bottom: -4px;
        }

        &.skip{
          height: 50px;
          margin-top: -45px;
          flex:0.5;
          &:after {
            height: 50px;
            margin-top: 50px;
            border-right: 4px dashed #CCCCCC;
            display: block;
            content: '';
            margin-right: -4px;
          }
        }

        &.skip-reverse{
          height: 50px;
          margin-top: -45px;
          flex:0.5;
          &:before {
            height: 53px;
            margin-top: 50px;
            border-left: 4px dashed #CCCCCC;
            display: block;
            content: '';
            margin-right: -4px;
          }
        }

        &.skip-next{
          margin-top: 50px;
          height: 57px;
          flex: 0.5;
          border-top: 4px solid #CCCCCC;
          border-bottom: 4px dashed #CCCCCC;
          margin-bottom: -4px;
        }
      }

      .multi-step {
        &> :not(:first-child) {
          margin-top: 54px;
        }
      }
      .skip-step {
        margin-top: 62px;
        border-top: 4px solid #CCCCCC;
        padding-top: 41px;
      }
    }

  }
</style>